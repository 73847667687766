var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"p-0 m-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-flex',{staticClass:"d-flex font-level-3-bold my-auto"},[_c('span',{staticClass:"detail-svg-icon mr-2"},[_c('span',{staticClass:"svg-icon svg-icon-lg mr-2 svg-v-list-icon"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/product.svg')}})],1)]),_c('h1',{staticClass:"form-title font-size-20 font-weight-700"},[_vm._v(" Competitors Assets ")])])],1)],1)],1),_c('ListingTable',{attrs:{"columnCount":3,"dataLoading":_vm.dataLoading,"rowData":_vm.dataRows},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th",attrs:{"width":"100"}},[_vm._v("Comp. Asset #")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Comp. Asset's Info")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Price")])])])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',{staticClass:"custom-border-bottom custom-border-top"},[(_vm.dataRows.length > 0)?_vm._l((_vm.dataRows),function(row,index){return _c('tr',{key:index,class:{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              },attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                  _vm.getDefaultRoute('product.detail', {
                    params: { id: row.product_id },
                  })
                )}}},[_c('td',{staticClass:"simple-table-td"},[_c('Barcode',{attrs:{"route":"service.detail","barcode":row.product_barcode,"id":row.id}})],1),_c('td',{staticClass:"simple-table-td"},[_c('div',{staticClass:"product-listing-customer"},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Name: ")]),_vm._v(" "+_vm._s(row.product_name)+" ")]),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Part Number: ")]),(row.products_part_number)?[_vm._v(_vm._s(row.products_part_number))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Part Number")])]],2)])]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[_c('div',{staticClass:"product-listing-invoice"},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Purchasing Price: ")]),(row.supplier_price)?[_vm._v(_vm._s(_vm.$accountingJS.formatMoney(row.supplier_price)))]:[_c('span',[_vm._v(" $ 0.00")])]],2),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Supplier Code: ")]),(row.part_number)?[_vm._v(_vm._s(row.part_number))]:[_c('span',[_vm._v("no supplier code")])]],2)])])])}):(!_vm.dataLoading)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no Competitors Assets at the moment. ")])])]):_vm._e()],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }